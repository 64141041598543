import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'email',
    label: i18n('user.fields.email'),
  },
  {
    name: 'fullName',
    label: i18n('user.fields.fullName'),
  },
  {
    name: 'role',
    label: i18n('user.fields.role'),
  },
  {
    name: 'units',
    label: i18n('user.fields.units'),
    render: exporterRenders.arrayObject('businessName'),
  },
  {
    name: 'chain',
    label: i18n('user.fields.chain'),
    render: exporterRenders.object('name'),
  },
  {
    name: 'lastLoginAt',
    label: i18n('user.fields.lastLoginAt'),
    render: exporterRenders.datetime(),
  },
];
