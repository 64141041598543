import { CardMedia } from '@mui/material';
import { selectMuiSettings } from 'src/modules/mui/muiSelectors';

export function BrandLogo(props) {
  const { darkMode, transparentSidenav, whiteSidenav } = selectMuiSettings();

  let brand = resources.brand.dark;

  if (props.brand && resources.brand[props.brand]) {
    brand = resources.brand[props.brand];
  } else if (props.sidenav) {
    brand =
      (transparentSidenav && !darkMode) || whiteSidenav
        ? resources.brand.dark
        : resources.brand.white;
  }

  return (
    <CardMedia
      src={props.src || brand}
      component="img"
      sx={{
        maxWidth: '100%',
        width: props.width ? props.width : 'auto',
        height: props.height ? props.height : 'auto',
        margin: 0,
        objectFit: 'cover',
        objectPosition: 'center',
      }}
    />
  );
}

const resources = {
  brand: {
    white: '/images/IconClaro.png',
    dark: '/images/Icon.png',
    horizontalWhite: '/images/Logo-Horizontal-Branca.png',
    horizontalDark: '/images/Logo-Horizontal-Padrão.png'
  },
};
