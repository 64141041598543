import moment from 'moment';
import { DEFAULT_MOMENT_FORMAT } from 'src/config/common';
import { getUserNameOrEmailPrefix } from 'src/modules/utils';

const exporterRenders = {
  stringArray: () => (value) => Array.isArray(value) ? value.join(', ') : '-',
  json: () => (value) => value ? JSON.stringify(value, null, 2) : '-',
  decimal: (fractionDigits?) => (value) =>
    value
      ? fractionDigits
        ? Number(value).toFixed(fractionDigits)
        : Number(value)
      : '-',
  boolean: () => (value) => String(Boolean(value)),
  relationToOne: (field = null) => (value) =>
    (value && (value[field] ?? value.id)) || '-',
  relationToMany: (field = null) => (value) =>
    Array.isArray(value) ? value.map((item) => item[field] ?? item.id).join(', ') : '-',
  relationToOneUser: () => (value) =>
    (value && getUserNameOrEmailPrefix(value)) || '-',
  relationToManyUser: () => (value) =>
    Array.isArray(value) ? value.map((item) => getUserNameOrEmailPrefix(item)).join(', ') : '-',
  filesOrImages: () => (value) =>
    Array.isArray(value) ? value.map((item) => item.downloadUrl).join(' ') : '-',
  datetime: () => (value) =>
    value ? moment(value).format(DEFAULT_MOMENT_FORMAT) : '-',
  object: (field) => (value) =>
    value && value[field] ? value[field] : '-',
  arrayObject: (field) => (value) =>
    Array.isArray(value) ? value.map((item) => item[field] ?? '-').join(', ') : '-',
};

export default exporterRenders;
