import ProfileService from 'src/modules/profile/profileService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'PROFILE_LIST';

const profileListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  doFetch:
    (reservationId) =>
      async (dispatch) => {
        try {
          dispatch({
            type: profileListActions.FETCH_STARTED,
          });

          const response = await ProfileService.list(reservationId);

          dispatch({
            type: profileListActions.FETCH_SUCCESS,
            payload: response,
          });
        } catch (error) {
          Errors.handle(error);

          dispatch({
            type: profileListActions.FETCH_ERROR,
          });
        }
      },
};

export default profileListActions;
