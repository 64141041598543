import { Route, Switch, Redirect } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import authSelectors from 'src/modules/auth/authSelectors';
import CustomLoadable from 'src/view/shared/CustomLoadable';
import EmailUnverifiedRoute from 'src/view/shared/routes/EmailUnverifiedRoute';
import EmptyPermissionsRoute from 'src/view/shared/routes/EmptyPermissionsRoute';
import muiSelectors from 'src/modules/mui/muiSelectors';
import PrivateRoute from 'src/view/shared/routes/PrivateRoute';
import ProgressBar from 'src/view/shared/ProgressBar';
import PublicRoute from 'src/view/shared/routes/PublicRoute';
import routes from 'src/view/routes';

function RoutesComponent(props) {
  const isInitialMount = useRef(true);

  const authLoading = useSelector(
    authSelectors.selectLoadingInit,
  );
  const muiSettingsLoading = useSelector(
    muiSelectors.selectLoading,
  );
  const loading = authLoading || muiSettingsLoading;
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      ProgressBar.start();
      return;
    }

    if (!loading) {
      ProgressBar.done();
    }
  }, [loading]);

  if (loading) {
    return <div />;
  }

  const query = new URLSearchParams(location.search);
  const hasQueryParams = query.has('hotelId') || query.has('totemId') || query.has('lineCode');
  const hasGuestParams = query.has('reservationInfo');

  return (
    <>
      <Switch>
        {routes.publicRoutes.map((route) => (
          <PublicRoute
            key={route.path}
            exact
            path={route.path}
            currentUser={currentUser}
            component={CustomLoadable({
              loader: route.loader,
            })}
          />
        ))}

        {routes.guestRoutes.map((route) => {
          if (route.path === '/guest' && !hasQueryParams && !hasGuestParams) {
            return (
              <Route
                key={route.path}
                exact={route.exact}
                path={route.path}
                render={() => <Redirect to="/guest/config" />}
              />
            );
          }

          return (
            <PublicRoute
              key={route.path}
              exact={route.exact}
              path={route.path}
              currentUser={currentUser}
              component={CustomLoadable({
                loader: route.loader,
              })}
            />
          );
        })}

        {routes.emailUnverifiedRoutes.map((route) => (
          <EmailUnverifiedRoute
            key={route.path}
            exact
            path={route.path}
            currentUser={currentUser}
            component={CustomLoadable({
              loader: route.loader,
            })}
          />
        ))}

        {routes.emptyPermissionsRoutes.map((route) => (
          <EmptyPermissionsRoute
            key={route.path}
            exact
            path={route.path}
            currentUser={currentUser}
            component={CustomLoadable({
              loader: route.loader,
            })}
          />
        ))}

        {routes.privateRoutes
          .filter((route) => !route.virtual)
          .map((route) => (
            <PrivateRoute
              key={route.path}
              currentUser={currentUser}
              permissionRequired={route.permissionRequired}
              path={route.path}
              component={CustomLoadable({
                loader: route.loader,
              })}
              exact={Boolean(route.exact)}
            />
          ))}

        {routes.simpleRoutes.map((route) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            component={CustomLoadable({
              loader: route.loader,
            })}
          />
        ))}

      </Switch>

    </>
  );
}

export default RoutesComponent;
