import virtualLineService from 'src/modules/virtualLine/virtualLineServices';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { i18n } from 'src/i18n';

const prefix = 'VIRTUAL_LINE';

const virtualLineActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  doFetchLine:
    async (hotelId, reservationId, profile) => {
      try {
        const response = await virtualLineService.enterLine(hotelId, reservationId, profile);

        return response
      } catch (error) {
        Errors.handle(error);

      }
    },

  doFetchVirtualLine:
    async (dispatch) => {
      try {
        dispatch({
          type: virtualLineActions.FETCH_STARTED,
        });

        const response = await virtualLineService.registerEquipament();
        dispatch({
          type: virtualLineActions.FETCH_SUCCESS,
          payload: response,
        });
        return response
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: virtualLineActions.FETCH_ERROR,
        });
      }
    },


  doStartLine: (hotelId, reservationId, currentLineUser, profile, preferential) => async (dispatch) => {
    try {
      dispatch({
        type: virtualLineActions.FETCH_STARTED,
      });

      const response = await virtualLineService.passwordLine(hotelId, reservationId, currentLineUser, profile, preferential);
      dispatch({
        type: virtualLineActions.FETCH_SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: virtualLineActions.FETCH_ERROR,
      });
    }
  },

  doFetchLinePasswords: (hotelId, guicheCode) => async (dispatch) => {
    try {
      dispatch({
        type: virtualLineActions.FETCH_STARTED,
      });

      const response = await virtualLineService.getpasswordLine(hotelId, guicheCode);
      dispatch({
        type: virtualLineActions.FETCH_SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: virtualLineActions.FETCH_ERROR,
      });
    }
  },

  doFetchLineUsers: (userId, guicheCode) => async (dispatch) => {
    try {
      dispatch({
        type: virtualLineActions.FETCH_STARTED,
      });

      const response = await virtualLineService.getpasswordLineGuest(userId, guicheCode);
      dispatch({
        type: virtualLineActions.FETCH_SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: virtualLineActions.FETCH_ERROR,
      });
    }
  },

  doUpdateStatus: (id, status, unit, guiche) => async (dispatch) => {
    try {
      dispatch({
        type: virtualLineActions.FETCH_STARTED,
      });

      const response = await virtualLineService.updatePasswordLineStatus(
        id,
        status,
        unit,
        guiche
      );
      dispatch({
        type: virtualLineActions.FETCH_SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: virtualLineActions.FETCH_ERROR,
      });
    }
  },


};

export default virtualLineActions;
