import authAxios from 'src/modules/shared/axios/authAxios';

export default class ProfileService {

  static async list(reservationId) {

    const response = await authAxios.post(
      `/profile/list/${reservationId}`,
    );

    return response.data;
  }

  static async find(profileId) {
    if (profileId != "-100") {
      const response = await authAxios.get(
        `/profile/${profileId}`,
      );

      return response.data;
    }

  }

  static async createSharedProfile(reservationId) {

    const body = {
      reservationId
    };

    const response = await authAxios.post(
      `/profile/shared`,
      body
    );

    return response.data;
  }

  static async createAccompanyingProfile(reservationId) {

    const body = {
      reservationId
    };

    const response = await authAxios.post(
      `/profile/accompanying`,
      body
    );

    return response.data;
  }

  static async update(id, data) {

    const body = {
      data,
      id
    };

    const response = await authAxios.post(
      `/profile/${id}`,
      body
    );

    return response.data;
  }

  static async preCheckReservationsStatus(reservationId) {
    const body = {
      reservationId
    };

    const response = await authAxios.post(
      `/profile/preCheckReservationStatus`,
      body
    );

    return response.data;
  }

  static async preCheckIn(reservationId) {

    const body = {
      reservationId
    };

    const response = await authAxios.post(
      `/profile/preCheckIn`,
      body
    );

    return response.data;
  }

  static async checkIn(reservationId, roomId) {
    const body = {
      reservationId,
      roomId
    };

    const response = await authAxios.post(
      `/profile/checkIn`,
      body
    );

    return response.data;
  }

  static async getNotes(hotelId, reservationId) {
    const response = await authAxios.get(
      `/profile/notesList/${hotelId}/${reservationId}`,
    );

    return response
  }
}
