import authAxios from 'src/modules/shared/axios/authAxios';

export default class virtualLineService {
  static async registerEquipament() {
    const response = await authAxios.post(
      `/virtualLine/equipament`,
    );
    return response?.data;
  }
  static async enterLine(hotelId, reservationId, profile) {
    const body = {
      ...profile,
      reservationId
    }
    const response = await authAxios.post(
      `/virtualLine/${hotelId}`,
      body
    );
    return response?.data;
  }

  static async passwordLine(hotelId, reservationId, profile, currentLineUser, preferential) {
    const body = {
      hotelId,
      reservationId,
      ...profile,
      currentLineUser,
      preferential
    };
    const response = await authAxios.post(
      `/createLinePassword`,
      body
    );
    return response?.data;
  }

  static async getpasswordLine(hotelId: any, guicheCode?: string) {
    const response = await authAxios.get(`/getLinePassword`, {
      params: { hotelId: hotelId, guicheCode: guicheCode },
    });
    return response?.data;
  }

  static async getpasswordLineGuest(hotelId: any, userId?: string) {
    const response = await authAxios.get(`/findGuestLineInformation`, {
      params: { hotelId: hotelId, userId: userId },
    });
    return response?.data;
  }

  static async updatePasswordLineStatus(id, status, hotelId, guicheCode, comment?) {
    const response = await authAxios.patch(`/updateLineStatus`, { id, status, hotelId, guicheCode, comment });
    return response?.data;
  }

}
