import { i18n } from 'src/i18n';
import { Icon } from '@mui/material';
import config from 'src/config';
import Permissions from 'src/security/permissions';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import Apartment from '@mui/icons-material/Apartment';
import SettingsIcon from '@mui/icons-material/Settings';

const permissions = Permissions.values;

const visibleExtraMenus = false;

interface IPermission {
  id: string;
  allowedRoles: string[];
  allowedStorage?: any[];
}

interface IMenu {
  key?: string;
  path?: string;
  exact?: boolean;
  icon: any;
  name: string;
  permissionRequired?: IPermission;
  collapse?: (boolean | IMenu)[];
  noCollapse?: boolean;
}

type TMenus = Array<boolean | IMenu>;

const menus: TMenus = [
  // {
  //   path: '/',
  //   exact: true,
  //   icon: <Icon>dashboard</Icon>,
  //   name: i18n('dashboard.menu'),
  //   permissionRequired: permissions.dashboardRead,
  // },

  {
    path: '/chain',
    name: i18n('collapses.chain.menu'),
    icon: <Icon>assignment</Icon>,
    permissionRequired: permissions.chainRead,
  },

  {
    path: '/unit',
    name: i18n('collapses.unit.menu'),
    icon: <Apartment />,
    permissionRequired: permissions.unitRead,
  },

  {
    path: '/user',
    name: i18n('user.menu'),
    permissionRequired: permissions.userRead,
    icon: <Icon>person</Icon>,
  },

  {
    path: '/reservation',
    name: i18n('collapses.reservation.menu'),
    icon: <Icon>assignment</Icon>,
    permissionRequired: permissions.reservationRead,
  },

  {
    path: '/virtualLine',
    name: i18n('collapses.virtualLine.menu'),
    permissionRequired: permissions.virtualLineRead,
    icon: <EmojiPeopleIcon />,
  },

  {
    path: '/virtualLineConfig',
    name: i18n('collapses.virtualLineConfig.menu'),
    permissionRequired: permissions.virtualLineConfig,
    icon: <SettingsIcon />,
  },
].filter(Boolean);

const profileRoutes: TMenus = [
  {
    name: i18n('auth.profile.title'),
    path: '/profile',
    icon: <Icon>person_outline</Icon>,
  },
  {
    path: '/audit-logs',
    name: i18n('auditLog.menu'),
    permissionRequired: permissions.auditLogRead,
    icon: <Icon>restore</Icon>,
  },
].filter(Boolean);

export { menus, profileRoutes };
