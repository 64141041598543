import PermissionChecker from 'src/modules/auth/permissionChecker';
import React from 'react';
import {
  Redirect,
  Route,
  useLocation,
} from 'react-router-dom';
import Layout from 'src/view/layout/Layout';
import config from 'src/config';
import Spinner from 'src/view/shared/Spinner';

function PrivateRoute({
  component: Component,
  currentUser,
  permissionRequired,
  ...rest
}) {
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        const permissionChecker = new PermissionChecker(
          currentUser,
        );

        if (!permissionChecker.isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: '/auth/signin',
                state: { from: location },
              }}
            />
          );
        }

        if (!permissionChecker.isEmailVerified) {
          return <Redirect to="/auth/email-unverified" />;
        }

        if (permissionChecker.isEmptyPermissions) {
          return (
            <Redirect to="/auth/empty-permissions" />
          );
        }

        if (!permissionChecker.match(permissionRequired)) {
          if (rest.path === '/') {
            return <Redirect to="/" />;
          }
          return <Redirect to="/403" />;
        }

        if (!currentUser) {
          return <Spinner />;
        }

        if (permissionChecker.match(permissionRequired) && rest.path === '/') {
          switch (permissionChecker.currentUserRole) {
            case 'admin':
              return <Redirect to="/chain" />;
            case 'chainManager':
              return <Redirect to="/unit" />;
            default:
              return <Redirect to="/reservation" />;
          }
        }

        return (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
}

export default PrivateRoute;
