const DEFAULT_MOMENT_FORMAT = 'DD/MM/YYYY hh:mm A';
const DEFAULT_MOMENT_FORMAT_DATE_ONLY = 'DD/MM/YYYY';
const DEFAULT_PICKER_FORMAT = 'DD/MM/YYYY hh:mm a';
const DEFAULT_PICKER_FORMAT_DATE_ONLY = 'DD/MM/YYYY';

export {
  DEFAULT_MOMENT_FORMAT,
  DEFAULT_MOMENT_FORMAT_DATE_ONLY,
  DEFAULT_PICKER_FORMAT,
  DEFAULT_PICKER_FORMAT_DATE_ONLY,
};
