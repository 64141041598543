import Roles from 'src/security/roles';
import Storage from 'src/security/storage';

const storage = Storage.values;
const roles = Roles.values;

class Permissions {
  static get values() {
    return {
      dashboardRead: {
        id: 'dashboardRead',
        allowedRoles: [roles.admin, roles.chainManager, roles.unitManager, roles.receptionist],
      },

      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.admin, roles.chainManager, roles.unitManager],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [roles.admin, roles.chainManager, roles.unitManager],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [
          roles.admin,
          roles.chainManager,
          roles.unitManager,
        ],
      },
      userImport: {
        id: 'userImport',
        allowedRoles: [roles.admin, roles.chainManager, roles.unitManager],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [
          roles.admin,
          roles.chainManager,
          roles.unitManager,
        ],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [roles.admin, roles.chainManager, roles.unitManager],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.admin, roles.chainManager, roles.unitManager],
      },
      muiEdit: {
        id: 'muiEdit',
        allowedRoles: [
          roles.admin,
          roles.chainManager,
          roles.unitManager,
          roles.receptionist,
        ],
      },

      chainImport: {
        id: 'chainImport',
        allowedRoles: [roles.admin],
      },
      chainCreate: {
        id: 'chainCreate',
        allowedRoles: [roles.admin],
      },
      chainEdit: {
        id: 'chainEdit',
        allowedRoles: [roles.admin],
      },
      chainDestroy: {
        id: 'chainDestroy',
        allowedRoles: [roles.admin],
      },
      chainRead: {
        id: 'chainRead',
        allowedRoles: [roles.admin],
      },
      chainAutocomplete: {
        id: 'chainAutocomplete',
        allowedRoles: [roles.admin, roles.chainManager, roles.unitManager, roles.receptionist],
      },

      unitImport: {
        id: 'unitImport',
        allowedRoles: [roles.admin, roles.chainManager],
      },
      unitCreate: {
        id: 'unitCreate',
        allowedRoles: [roles.admin],
      },
      unitEdit: {
        id: 'unitEdit',
        allowedRoles: [roles.admin, roles.chainManager],
      },
      unitDestroy: {
        id: 'unitDestroy',
        allowedRoles: [roles.admin, roles.chainManager],
      },
      unitRead: {
        id: 'unitRead',
        allowedRoles: [roles.admin, roles.chainManager, roles.unitManager],
      },
      unitAutocomplete: {
        id: 'unitAutocomplete',
        allowedRoles: [roles.admin, roles.chainManager, roles.unitManager, roles.receptionist],
      },

      reservationRead: {
        id: 'reservationRead',
        allowedRoles: [roles.unitManager, roles.receptionist],
      },
      reservationEdit: {
        id: 'reservationEdit',
        allowedRoles: [roles.unitManager, roles.receptionist],
      },

      profileRead: {
        id: 'profileRead',
        allowedRoles: [roles.unitManager, roles.receptionist],
      },
      profileEdit: {
        id: 'profileEdit',
        allowedRoles: [roles.unitManager, roles.receptionist],
      },
      profileCreate: {
        id: 'profileCreate',
        allowedRoles: [roles.unitManager, roles.receptionist],
      },
      virtualLineRead: {
        id: 'virtualLineRead',
        allowedRoles: [roles.unitManager, roles.receptionist],
      },
      virtualLineConfig: {
        id: 'virtualLineConfig',
        allowedRoles: [roles.unitManager, roles.receptionist, roles.chainManager, roles.admin],
      }
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
