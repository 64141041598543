import list from 'src/modules/chain/list/chainListReducers';
import form from 'src/modules/chain/form/chainFormReducers';
import view from 'src/modules/chain/view/chainViewReducers';
import destroy from 'src/modules/chain/destroy/chainDestroyReducers';
import importerReducer from 'src/modules/chain/importer/chainImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
