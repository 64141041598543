import ReservationService from 'src/modules/reservation/reservationService';
import selectors from 'src/modules/reservation/list/reservationListSelectors';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { i18n } from 'src/i18n';

const prefix = 'RESERVATION_LIST';

const reservationListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  doClearAllSelected() {
    return {
      type: reservationListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: reservationListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: reservationListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: reservationListActions.RESETED,
    });

    dispatch(reservationListActions.doFetch());
  },

  doChangePagination:
    (pagination) => async (dispatch, getState) => {
      dispatch({
        type: reservationListActions.PAGINATION_CHANGED,
        payload: pagination,
      });

      dispatch(reservationListActions.doFetchCurrentFilter());
    },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: reservationListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(reservationListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter:
    () => async (dispatch, getState) => {
      const filter = selectors.selectFilter(getState());
      const rawFilter = selectors.selectRawFilter(
        getState(),
      );
      dispatch(
        reservationListActions.doFetch(filter, rawFilter, true),
      );
    },

  doFetch:
    (filter?, rawFilter?, keepPagination = true) =>
      async (dispatch, getState) => {
        try {
          dispatch({
            type: reservationListActions.FETCH_STARTED,
            payload: { filter, rawFilter, keepPagination },
          });

          const response = await ReservationService.list(
            filter,
            selectors.selectOrderBy(getState()),
            selectors.selectLimit(getState()),
            selectors.selectOffset(getState()),
          );

          dispatch({
            type: reservationListActions.FETCH_SUCCESS,
            payload: {
              rows: response.rows,
              count: response.count,
            },
          });
        } catch (error) {
          Errors.handle(error);

          dispatch({
            type: reservationListActions.FETCH_ERROR,
          });
        }
      },

  doUpdateReservations: () => async (dispatch) => {
    try {
      dispatch({
        type: reservationListActions.FETCH_STARTED
      });
      const response = await ReservationService.updateReservations();
      dispatch(reservationListActions.doFetchCurrentFilter());

      dispatch({
        type: reservationListActions.FETCH_SUCCESS
      });

      Message.success(i18n('entities.reservation.updateReservations.success'));
    } catch (error) {
      dispatch({
        type: reservationListActions.FETCH_ERROR,
      });
      Errors.handle(error);
    }
  }
};

export default reservationListActions;
