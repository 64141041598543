import { i18n } from 'src/i18n';
import schemas from 'src/modules/shared/yup/yupImporterSchemas';

export default [
  {
    name: 'firstName',
    label: i18n('user.fields.firstName'),
    schema: schemas.string(i18n('user.fields.firstName')),
  },
  {
    name: 'lastName',
    label: i18n('user.fields.lastName'),
    schema: schemas.string(i18n('user.fields.lastName')),
  },
  {
    name: 'email',
    label: i18n('user.fields.email'),
    schema: schemas.email(i18n('user.fields.email')),
  },
  {
    name: 'password',
    label: i18n('user.fields.password'),
    schema: schemas.string(i18n('user.fields.password')),
  },
  {
    name: 'role',
    label: i18n('user.fields.role') + ' ' + '(R, UM)',
    schema: schemas.string(i18n('user.fields.role')),
  },
  {
    name: 'chain',
    label: i18n('user.fields.chain'),
    schema: schemas.string(i18n('user.fields.chain')),
  },
  {
    name: 'units',
    label: i18n('user.fields.units'),
    schema: schemas.string(i18n('user.fields.units')),
  },
];
