import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'cnpj',
    label: i18n('entities.unit.fields.cnpj'),
    schema: schemas.string(
      i18n('entities.unit.fields.cnpj'),
      {
        max: 255,
      },
    ),
  },
  {
    name: 'businessName',
    label: i18n('entities.unit.fields.businessName'),
    schema: schemas.string(
      i18n('entities.unit.fields.businessName'),
      {
        max: 255,
      }
    )
  },
  {
    name: 'internalCode',
    label: i18n('entities.unit.fields.internalCode'),
    schema: schemas.string(
      i18n('entities.unit.fields.internalCode'),
      {
        max: 255,
      }
    )
  },
  {
    name: 'fantasyName',
    label: i18n('entities.unit.fields.fantasyName'),
    schema: schemas.string(
      i18n('entities.unit.fields.fantasyName'),
      {
        max: 255,
      }
    )
  },
  {
    name: 'chain',
    label: i18n('entities.unit.fields.chain'),
    schema: schemas.relationToOne(
      i18n('entities.unit.fields.chain')
    )
  },
  {
    name: 'inspected',
    label: i18n('entities.unit.fields.inspected'),
    schema: schemas.boolean(
      i18n('entities.unit.fields.inspected'),
    )
  },
];
