import config from 'src/config';
import Permissions from 'src/security/permissions';

const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/virtualLine',
    collapseName: 'virtualLine',
    i18n: 'collapses.virtualLine.menu',
    loader: () =>
      import('src/view/virtual-line/VirtualLine'),
  },

  {
    path: '/virtualLineConfig',
    collapseName: 'virtualLineConfig',
    i18n: 'collapses.virtualLineConfig.menu',
    loader: () =>
      import('src/view/virtual-line/virtualLineConfig'),
  },

  {
    path: '/',
    i18n: 'dashboard.menu',
    loader: () =>
      import('src/view/dashboard/DashboardPage'),
    permissionRequired: permissions.dashboardRead,
    exact: true,
  },

  {
    path: '/person-name-breadcrumb',
    collapseName: 'my-profile',
    i18n: 'roles.admin.label',
    parent: '/',
    redirect: '/profile',
    permissionRequired: null,
    virtual: true,
  },

  {
    path: '/profile',
    collapseName: 'my-profile',
    i18n: 'auth.profile.title',
    parent: '/person-name-breadcrumb',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/user',
    i18n: 'user.menu',
    collapseName: 'user',
    parent: '/',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    i18n: 'user.new.title',
    collapseName: 'user',
    parent: '/user',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/importer',
    i18n: 'user.importer.title',
    collapseName: 'user',
    parent: '/user',
    loader: () =>
      import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },

  {
    path: '/user/:id/edit',
    i18n: 'user.edit.title',
    collapseName: 'user',
    parent: '/user',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },

  {
    path: '/user/:id',
    i18n: 'user.view.title',
    collapseName: 'user',
    parent: '/user',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/audit-logs',
    collapseName: 'my-profile',
    i18n: 'auditLog.menu',
    parent: '/person-name-breadcrumb',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/chain',
    collapseName: 'chain',
    i18n: 'entities.chain.menu',
    parent: '/',
    loader: () => import('src/view/chain/list/ChainListPage'),
    permissionRequired: permissions.chainRead,
    exact: true,
  },

  {
    path: '/chain/new',
    collapseName: 'chain',
    i18n: 'entities.chain.new.title',
    parent: '/chain',
    loader: () => import('src/view/chain/form/ChainFormPage'),
    permissionRequired: permissions.chainCreate,
    exact: true,
  },

  {
    path: '/chain/importer',
    collapseName: 'chain',
    i18n: 'entities.chain.importer.title',
    parent: '/chain',
    loader: () =>
      import('src/view/chain/importer/ChainImporterPage'),
    permissionRequired: permissions.chainImport,
    exact: true,
  },

  {
    path: '/chain/:id/edit',
    collapseName: 'chain',
    i18n: 'entities.chain.edit.title',
    parent: '/chain',
    loader: () => import('src/view/chain/form/ChainFormPage'),
    permissionRequired: permissions.chainEdit,
    exact: true,
  },

  {
    path: '/chain/:id',
    collapseName: 'chain',
    i18n: 'entities.chain.view.title',
    parent: '/chain',
    loader: () => import('src/view/chain/view/ChainViewPage'),
    permissionRequired: permissions.chainRead,
    exact: true,
  },

  {
    path: '/unit',
    collapseName: 'unit',
    i18n: 'entities.unit.menu',
    parent: '/',
    loader: () => import('src/view/unit/list/UnitListPage'),
    permissionRequired: permissions.unitRead,
    exact: true,
  },

  {
    path: '/unit/new',
    collapseName: 'unit',
    i18n: 'entities.unit.new.title',
    parent: '/unit',
    loader: () => import('src/view/unit/form/UnitFormPage'),
    permissionRequired: permissions.unitCreate,
    exact: true,
  },

  {
    path: '/unit/importer',
    collapseName: 'unit',
    i18n: 'entities.unit.importer.title',
    parent: '/unit',
    loader: () =>
      import('src/view/unit/importer/UnitImporterPage'),
    permissionRequired: permissions.unitImport,
    exact: true,
  },

  {
    path: '/unit/:id/edit',
    collapseName: 'unit',
    i18n: 'entities.unit.edit.title',
    parent: '/unit',
    loader: () => import('src/view/unit/form/UnitFormPage'),
    permissionRequired: permissions.unitEdit,
    exact: true,
  },

  {
    path: '/unit/:id',
    collapseName: 'unit',
    i18n: 'entities.unit.view.title',
    parent: '/unit',
    loader: () => import('src/view/unit/view/UnitViewPage'),
    permissionRequired: permissions.unitRead,
    exact: true,
  },

  {
    path: '/reservation',
    collapseName: 'reservation',
    i18n: 'entities.reservation.menu',
    parent: '/',
    loader: () => import('src/view/reservation/list/ReservationListPage'),
    permissionRequired: permissions.reservationRead,
    exact: true,
  },

  {
    path: '/reservation/:reservationId/checkIn',
    collapseName: 'reservation',
    i18n: 'entities.profile.menu',
    parent: '/reservation',
    loader: () => import('src/view/profile/list/ProfileListPage'),
    permissionRequired: permissions.profileCreate,
    exact: true,
  },

  {
    path: '/reservation/:reservationId/:profileId/edit',
    collapseName: 'reservation',
    i18n: 'entities.profile.edit.title',
    parent: '/reservation',
    loader: () => import('src/view/profile/form/ProfileFormPage'),
    permissionRequired: permissions.profileCreate,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/virtualLineGuest',
    collapseName: 'virtualLineGuest',
    i18n: 'collapses.virtualLine.menu',
    loader: () =>
      import('src/view/virtual-line/VirtualLineGuest'),
  },
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/SignupPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () =>
      import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const guestRoutes = [
  {
    path: '/guest/config',
    parent: '/',
    loader: () => import('src/view/guest/totem/TotemConfigScreen'),
  },
  {
    path: '/guest',
    parent: '/',
    exact: true,
    loader: () => import('src/view/guest/totem/TotemPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  guestRoutes,
  simpleRoutes,
};

export function findRoute(url = null, routes = []) {
  return (
    !!url &&
    (routes.find((route) => url === route.path) ||
      routes.find(
        (route) =>
          /\/:[\w\d_-]+/g.test(route.path) &&
          new RegExp(
            `^${route.path.replace(
              /:[\w\d_-]+/g,
              '[\\w\\d]+',
            )}$`,
          ).test(url),
      ))
  );
}

export function matchedRoutes(
  url = null,
  exactOnly = false,
) {
  if (url === null || url === undefined) {
    return null;
  }

  let routes = [];

  const searchRouteStack = (url, exactOnly) => {
    const found = findRoute(url, privateRoutes);

    if (exactOnly === true) {
      return found;
    }

    if (found) {
      routes.push(found);
      if (found.parent && found.parent !== '/') {
        return searchRouteStack(found.parent, exactOnly);
      }
    }

    routes.reverse();

    return routes;
  };

  return searchRouteStack(url, exactOnly);
}
