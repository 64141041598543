import guestService from 'src/modules/guest/guestServices';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { Base64ToPdfConverter } from '../shared/common';
import { i18n } from 'src/i18n';

const prefix = 'GUEST';

const guestActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  FETCH_ROOMS_STARTED: `${prefix}_FETCH_ROOMS_STARTED`,
  FETCH_ROOMS_SUCCESS: `${prefix}_FETCH_ROOMS_SUCCESS`,
  FETCH_ROOMS_ERROR: `${prefix}_FETCH_ROOMS_ERROR`,

  FETCH_RECORD_SUCCESS: `${prefix}_FETCH_RECORD_SUCCESS`,
  FETCH_RECORD_ERROR: `${prefix}FETCH_RECORD_ERROR`,

  FETCH_CHECKIN_STARTED: `${prefix}_FETCH_CHECKIN_STARTED`,
  FETCH_CHECKIN_SUCCESS: `${prefix}_FETCH_CHECKIN_SUCCESS`,
  FETCH_CHECKIN_ERROR: `${prefix}FETCH_CHECKIN_ERROR`,

  doFetch:
    async (reservationId, dispatch, hotelId?) => {
      try {
        dispatch({
          type: guestActions.FETCH_STARTED,
        });

        const response = await guestService.list(reservationId, hotelId);
        dispatch({
          type: guestActions.FETCH_SUCCESS,
          payload: response,
        });
        return response
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: guestActions.FETCH_ERROR,
        });
      }
    },
  doFetchTotem:
    async (reservationId, dispatch, hotelId?, primeira?) => {
      try {
        dispatch({
          type: guestActions.FETCH_STARTED,
        });

        const response = await guestService.listOrGetFromOpera(reservationId, hotelId, primeira);
        dispatch({
          type: guestActions.FETCH_SUCCESS,
          payload: response,
        });
        return response
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: guestActions.FETCH_ERROR,
        });
      }
    },
  doGetProfileByRsvId:
    async (reservationId, profileId, hotelId?) => {
      const response = await guestService.listProfile(reservationId, profileId, hotelId);
      return response;
    },
  doProfileUpdate: async (profileId, data) => {
    const response = await guestService.updateProfile(profileId, data)
    return response;
  },
  doPreCheckIn: (reservationId) => async (dispatch) => {
    try {
      if (!reservationId) {
        Message.error("Reservation Id is undefined");
        return;
      }

      dispatch({
        type: guestActions.FETCH_ROOMS_STARTED,
      });

      const rooms = await guestService.preCheckIn(reservationId);

      if (rooms) {
        dispatch({
          type: guestActions.FETCH_ROOMS_SUCCESS,
          payload: rooms
        });
      } else {
        dispatch({
          type: guestActions.FETCH_ROOMS_ERROR,
        });
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: guestActions.FETCH_ROOMS_ERROR,
      });
    }
  },

  doFetchAvailableRooms: (hotelId, roomType) => async (dispatch) => {
    try {
      const rooms = await guestService.fetchAvailableRooms(hotelId, roomType);
      if (rooms) {
        dispatch({
          type: guestActions.FETCH_ROOMS_SUCCESS,
          payload: rooms
        });
      } else {
        dispatch({
          type: guestActions.FETCH_ROOMS_ERROR,
        });
      }
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: guestActions.FETCH_ROOMS_ERROR
      });
    }
  },

  doCheckIn: (reservationId, roomId) => async (dispatch) => {
    try {
      dispatch({
        type: guestActions.FETCH_CHECKIN_STARTED,
      });
      const record = await guestService.checkIn(reservationId, roomId);
      if (record) {
        Message.success(i18n('entities.profile.checkIn.success'));
        dispatch({
          type: guestActions.FETCH_CHECKIN_SUCCESS,
          payload: record
        });
        //Base64ToPdfConverter(record?.registrationCard?.registrationCard);
        return true
      } else {

        Message.error(i18n('entities.profile.checkIn.error'));
        dispatch({
          type: guestActions.FETCH_CHECKIN_ERROR,
        });
        return false
      }
    } catch (error) {
      dispatch({
        type: guestActions.FETCH_CHECKIN_ERROR,
      });
      Errors.handle(error);

      return false
    }
  },

  doCheckInTotem: (reservationId, roomId) => async (dispatch) => {
    try {
      dispatch({
        type: guestActions.FETCH_CHECKIN_STARTED,
      });
      const record = await guestService.checkIn(reservationId, roomId);
      if (record) {
        // Message.success(i18n('entities.profile.checkIn.success'));
        dispatch({
          type: guestActions.FETCH_CHECKIN_SUCCESS,
          payload: record
        });
        //Base64ToPdfConverter(record?.registrationCard?.registrationCard);
        return true
      } else {

        // Message.error(i18n('entities.profile.checkIn.error'));
        dispatch({
          type: guestActions.FETCH_CHECKIN_ERROR,
        });
        return false
      }
    } catch (error) {
      dispatch({
        type: guestActions.FETCH_CHECKIN_ERROR,
      });
      Errors.handle(error);

      return false
    }
  },
};

export default guestActions;
