import ChainService from 'src/modules/chain/chainService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'CHAIN_VIEW';

const chainViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: chainViewActions.FIND_STARTED,
      });

      const record = await ChainService.find(id);

      dispatch({
        type: chainViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: chainViewActions.FIND_ERROR,
      });

      getHistory().push('/chain');
    }
  },
};

export default chainViewActions;
