import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.chain.fields.name'),
    schema: schemas.string(
      i18n('entities.chain.fields.name'),
      {
        max: 255,
      },
    ),
  },
  {
    name: 'appKey',
    label: i18n('entities.chain.fields.appKey'),
    schema: schemas.string(
      i18n('entities.chain.fields.appKey'),
      {
        max: 255,
      }
    )
  },
  {
    name: 'clientId',
    label: i18n('entities.chain.fields.clientId'),
    schema: schemas.string(
      i18n('entities.chain.fields.clientId'),
      {
        max: 255,
      }
    )
  },
  {
    name: 'clientSecret',
    label: i18n('entities.chain.fields.clientSecret'),
    schema: schemas.string(
      i18n('entities.chain.fields.clientSecret'),
      {
        max: 255,
      }
    )
  },
  {
    name: 'hostName',
    label: i18n('entities.chain.fields.hostName'),
    schema: schemas.string(
      i18n('entities.chain.fields.hostName'),
      {
        max: 255,
      }
    )
  },
  {
    name: 'apipassword',
    label: i18n('entities.chain.fields.operaPassword'),
    schema: schemas.string(
      i18n('entities.chain.fields.operaPassword'),
      {
        max: 255,
      }
    )
  },
  {
    name: 'apiusername',
    label: i18n('entities.chain.fields.operaUsername'),
    schema: schemas.string(
      i18n('entities.chain.fields.operaUsername'),
      {
        max: 255,
      }
    )
  },
  // TODO:
  // {
  //   name: 'username',
  //   label: i18n('entities.chain.fields.username'),
  //   schema: schemas.string(
  //     i18n('entities.chain.fields.username'),
  //     {
  //       max: 255,
  //     }
  //   )
  // },
  // {
  //   name: 'password',
  //   label: i18n('entities.chain.fields.password'),
  //   schema: schemas.string(
  //     i18n('entities.chain.fields.password'),
  //     {
  //       max: 255,
  //     }
  //   )
  // },
];

//apipassword/apiUsername