// Place the URL here with the /api suffix.
// Ex.:`https://domain.com/api`;
const backendUrl = `/api`;

// SwaggerUI Documentation URL
// Leave black if documentation should be hidden
const apiDocumentationUrl = `/documentation`;

/**
 * Frontend URL.
 */
const frontendUrl = {
  host: 'bup.com.br',
  protocol: 'https',
};

/**
 * Plan payments configuration.
 */
const isPlanEnabled = true;
const stripePublishableKey = '';

const limitNestedList = 5;

export default {
  apiDocumentationUrl,
  backendUrl,
  frontendUrl,
  isPlanEnabled,
  limitNestedList,
  stripePublishableKey,
};
