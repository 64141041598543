import ProfileService from 'src/modules/profile/profileService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';
import { Base64ToPdfConverter } from 'src/modules/shared/common';
import profileListActions from '../list/profileListActions';

const prefix = 'PROFILE_FORM';

const profileFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  FETCH_ROOMS_STARTED: `${prefix}_FETCH_ROOMS_STARTED`,
  FETCH_ROOMS_SUCCESS: `${prefix}_FETCH_ROOMS_SUCCESS`,
  FETCH_ROOMS_ERROR: `${prefix}_FETCH_ROOMS_ERROR`,

  FETCH_RECORD_SUCCESS: `${prefix}_FETCH_RECORD_SUCCESS`,
  FETCH_RECORD_ERROR: `${prefix}FETCH_RECORD_ERROR`,

  doInit: (profileId) => async (dispatch) => {
    try {
      dispatch({
        type: profileFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(profileId);

      if (isEdit) {
        record = await ProfileService.find(profileId);
      }

      dispatch({
        type: profileFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: profileFormActions.INIT_ERROR,
      });
    }
  },

  doCreateSharedProfile: (reservationId) => async (dispatch) => {
    try {
      dispatch({
        type: profileFormActions.UPDATE_STARTED,
      });

      const record = await ProfileService.createSharedProfile(reservationId);

      if (record) {
        dispatch({
          type: profileFormActions.UPDATE_SUCCESS,
        });
        dispatch(profileListActions.doFetch(reservationId));
        Message.success(i18n('entities.profile.createSharedProfile.success'));
      } else {
        Message.error(i18n('entities.profile.createSharedProfile.error'));
        dispatch({
          type: profileFormActions.UPDATE_ERROR,
        });
      }
    } catch (error) {
      dispatch({
        type: profileFormActions.UPDATE_ERROR,
      });
      Errors.handle(error);
    }
  },

  doCreateAccompanyingProfile: (reservationId) => async (dispatch) => {
    try {
      const record = await ProfileService.createAccompanyingProfile(reservationId);

      if (record) {
        dispatch(profileListActions.doFetch(reservationId));
        Message.success(i18n('entities.profile.createAccompanyingProfile.success'));
      } else {
        Message.error(i18n('entities.profile.createAccompanyingProfile.error'));
      }
    } catch (error) {
      Errors.handle(error);

    }
  },

  doUpdate: (profileId, values) => async (dispatch) => {
    try {
      dispatch({
        type: profileFormActions.UPDATE_STARTED,
      });

      const record = await ProfileService.update(profileId, values);
      if (record) {
        dispatch({
          type: profileFormActions.UPDATE_SUCCESS,
        });

        Message.success(i18n('entities.profile.update.success'));

        getHistory().push(`/reservation/${values.reservationId}/checkIn`);
      } else {
        dispatch({
          type: profileFormActions.UPDATE_ERROR,
        });

        Message.error(i18n('entities.profile.update.error'));
      }

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: profileFormActions.UPDATE_ERROR,
      });
    }
  },

  doPreCheckIn: (reservationId) => async (dispatch) => {
    try {
      dispatch({
        type: profileFormActions.FETCH_ROOMS_STARTED,
      });

      const rooms = await ProfileService.preCheckIn(reservationId);

      if (rooms) {
        dispatch({
          type: profileFormActions.FETCH_ROOMS_SUCCESS,
          payload: rooms
        });
      } else {
        dispatch({
          type: profileFormActions.FETCH_ROOMS_ERROR,
        });
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: profileFormActions.FETCH_ROOMS_ERROR,
      });
    }
  },

  doCheckIn: (reservationId, roomId, setLoadingCard) => async (dispatch) => {
    try {
      setLoadingCard(true)
      const record = await ProfileService.checkIn(reservationId, roomId);

      if (record.checkedIn) {
        Message.success(i18n('entities.profile.checkIn.success'));
        dispatch({
          type: profileFormActions.FETCH_RECORD_SUCCESS,
          payload: record
        });

        // Call profileListActions.doFetch to fetch updated data
        dispatch(profileListActions.doFetch(reservationId));

        // Open Pdf doc
        Base64ToPdfConverter(record?.registrationCard?.registrationCard);
        setLoadingCard(false)
      } else {

        Message.error(i18n('entities.profile.checkIn.error'));
        dispatch({
          type: profileFormActions.FETCH_RECORD_ERROR,
        });
        setLoadingCard(false)
      }
    } catch (error) {
      Errors.handle(error);
    }
  },
};

export default profileFormActions;
