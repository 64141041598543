import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'cnpj',
    label: i18n('entities.unit.fields.cnpj'),
  },
  {
    name: 'businessName',
    label: i18n('entities.unit.fields.businessName')
  },
  {
    name: 'internalCode',
    label: i18n('entities.unit.fields.internalCode')
  },
  {
    name: 'fantasyName',
    label: i18n('entities.unit.fields.fantasyName')
  },
  {
    name: 'chain',
    label: i18n('entities.unit.fields.chain'),
    render: exporterRenders.relationToOne('name')
  },
  {
    name: 'inspected',
    label: i18n('entities.unit.fields.inspected'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.unit.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'checkIn',
    label: i18n('entities.unit.fields.checkIn'),
  },
  {
    name: 'checkOut',
    label: i18n('entities.unit.fields.checkOut'),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.unit.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
