import authAxios from 'src/modules/shared/axios/authAxios';

export default class guestService {
  static async list(reservationId, hotelId?) {
    let query = hotelId ? `?hotelId=${hotelId}` : '';
    const response = await authAxios.post(
      `/guest/profile/list/${reservationId}${query}`,
    );
    return response?.data;
  }
  static async listProfile(reservationId, profileId, hotelId?) {
    let query = hotelId ? `?hotelId=${hotelId}` : '';
    const response = await authAxios.post(
      `/guest/profile/list/${reservationId}/${profileId}${query}`,
    );
    return response?.data;
  }
  static async listOrGetFromOpera(reservationId, hotelId?, primeira?) {
    let query = [];

    if (hotelId) {
      query.push(`hotelId=${hotelId}`);
    }

    if (primeira) {
      query.push(`primeira=${primeira}`);
    }

    // Junta os parâmetros com '&' e adiciona o '?' se existir pelo menos um parâmetro
    const queryString = query.length ? `?${query.join('&')}` : '';

    const response = await authAxios.post(
      `/guest/profile/listOrGetFromOpera/${reservationId}${queryString}`,
    );
    return response?.data;
  }

  static async updateProfile(id, data) {
    const body = {
      data
    }
    const response = await authAxios.patch(
      `/guest/profile/${id}`,
      body
    );
    return response?.data;
  }

  static async preCheckIn(reservationId) {
    const body = {
      reservationId
    };

    const response = await authAxios.post(
      `/guest/profile/preCheckIn`,
      body
    );

    return response?.data;
  }

  static async fetchAvailableRooms(hotelId, roomType) {
    const body = {
      hotelId,
      roomType
    };

    const response = await authAxios.post(
      `/guest/profile/availableRooms`,
      body
    );

    return response?.data;
  }

  static async checkIn(reservationId, roomId) {
    const body = {
      reservationId,
      roomId
    };

    const response = await authAxios.post(
      `/guest/profile/checkIn`,
      body
    );

    return response?.data;
  }

}
