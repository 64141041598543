import actions from 'src/modules/guest/guestActions';

const initialData = {

  data: {},
  loading: false,
  getRoomsLoading: false,
  rooms: [],
  record: null,
  registrationCard: null,
  checkinLoading: false,
  checkin: false
};

export default (state = initialData, { type, payload }) =>
{
  if (type === actions.FETCH_STARTED)
  {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.FETCH_SUCCESS)
  {
    return {
      ...state,
      loading: false,
      data: payload
    };
  }

  if (type === actions.FETCH_ERROR)
  {
    return {
      ...state,
      loading: false,
      data: {},
    };
  }

  // check-in
  if (type === actions.FETCH_ROOMS_STARTED)
  {
    return {
      ...state,
      getRoomsLoading: true,
    };
  }

  if (type === actions.FETCH_ROOMS_SUCCESS)
  {
    return {
      ...state,
      rooms: payload,
      getRoomsLoading: false,
    };
  }

  if (type === actions.FETCH_ROOMS_ERROR)
  {
    return {
      ...state,
      getRoomsLoading: false,
    };
  }

  if (type === actions.FETCH_CHECKIN_STARTED)
  {
    return {
      ...state,
      registrationCard: null,
      checkinLoading: true,
      checkin: false
    };
  }

  if (type === actions.FETCH_CHECKIN_SUCCESS)
  {
    return {
      ...state,
      registrationCard: payload.registrationCard,
      data: payload.reservationData,
      checkinLoading: false,
      checkin: true
    };
  }

  if (type === actions.FETCH_CHECKIN_ERROR)
  {
    return {
      ...state,
      registrationCard: null,
      checkinLoading: false,
      checkin: false
    };
  }

  return state;
};
