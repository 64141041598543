const initialState = {
  link: '',
  loading: false,
  error: null,
};

const tokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GENERATE_LINK_REQUEST':
      return { ...state, loading: true, error: null };
    case 'GENERATE_LINK_SUCCESS':
      return {
        ...state,
        loading: false,
        link: action.payload,
      };
    case 'GENERATE_LINK_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default tokenReducer;
