import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'name',
    label: i18n('entities.chain.fields.name'),
  },
  {
    name: 'appKey',
    label: i18n('entities.chain.fields.appKey')
  },
  {
    name: 'clientId',
    label: i18n('entities.chain.fields.clientId')
  },
  {
    name: 'clientSecret',
    label: i18n('entities.chain.fields.clientSecret')
  },
  {
    name: 'hostName',
    label: i18n('entities.chain.fields.hostName')
  },
  {
    name: 'apipassword',
    label: i18n('entities.chain.fields.operaPassword'),
  },
  {
    name: 'apiusername',
    label: i18n('entities.chain.fields.operaUsername'),
  },
  // TODO:
  // {
  //   name: 'username',
  //   label: i18n('entities.chain.fields.username'),
  // },
  // {
  //   name: 'password',
  //   label: i18n('entities.chain.fields.password'),
  // },
  // {
  //   name: 'createdAt',
  //   label: i18n('entities.chain.fields.createdAt'),
  //   render: exporterRenders.datetime(),
  // },
  // {
  //   name: 'updatedAt',
  //   label: i18n('entities.chain.fields.updatedAt'),
  //   render: exporterRenders.datetime(),
  // },
];
