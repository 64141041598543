import authAxios from 'src/modules/shared/axios/authAxios';

export default class ReservationService {
  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(
      `/reservation`,
      {
        params,
      },
    );

    return response.data;
  }

  static async updateReservations() {
    const response = await authAxios.post(
      `/reservation-update`,
    );

    return response.data;
  }
}
