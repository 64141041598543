

export function Base64ToPdfConverter(base64Code) {

  const byteCharacters = atob(base64Code);

  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Create a blob from the Uint8Array
  const blob = new Blob([byteArray], { type: 'application/pdf' });

  // Create a URL for the blob
  const url = URL.createObjectURL(blob);
  // Open the PDF in a new tab
  window.open(url, '_blank');

  URL.revokeObjectURL(url);

  // Release the URL object when the component is unmounted
  return url;
}
