import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import user from 'src/modules/user/userReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import form from 'src/modules/form/formReducers';
import mui from 'src/modules/mui/muiReducers';
import chain from 'src/modules/chain/chainReducers';
import unit from 'src/modules/unit/unitReducers';
import reservation from 'src/modules/reservation/reservationReducers';
import profile from 'src/modules/profile/profileReducers';
import guest from 'src/modules/guest/guestReducers';
import token from 'src/modules/token/tokenReducers';
import virtualLine from 'src/modules/virtualLine/virtualLineReducers';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    form,
    mui,
    layout,
    auth,
    user,
    auditLog,
    chain,
    unit,
    reservation,
    profile,
    guest,
    token,
    virtualLine
  });
